import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { map, range, get } from '../../lib/nodash';
import { Box, Select } from 'grommet';
import {
  format,
  eachMonthOfInterval,
  eachYearOfInterval,
  getDaysInMonth,
  getMonth,
  getDate,
  getYear,
  addYears,
  setDate as setDateFns,
  setMonth as setMonthFns,
  setYear as setYearFns,
  formatISO,
} from 'date-fns';

import useMobile from '../useMobile';

const InputDateTrioSelect = (props) => {
  const isMobile = useMobile();
  const onChange = get('onChange', props) || get('field.onChange', props);
  const value = get('value', props) || get('field.value', props);
  const name = get('name', props) || get('field.name', props);
  const valueMonth = value.split('-')[0];
  const valueDate = value.split('-')[1];
  const valueYear = value.split('-')[2];

  const valueConstructedDate = valueMonth
    ? new Date(valueYear, valueMonth - 1, valueDate - 1)
    : new Date();
  const [selectedDate, setSelectedDate] = useState(valueConstructedDate);
  const [month, setMonth] = useState(getMonth(selectedDate));
  const [date, setDate] = useState(getDate(selectedDate));
  const [year, setYear] = useState(getYear(selectedDate));

  useEffect(() => {
    const d = new Date();
    // const dated = setMonth(d, month)
    const cd = setYearFns(setMonthFns(setDateFns(d, date + 1), month), year);
    const formattedDate = formatISO(cd);

    if (get('form', props)) {
      props.form.setFieldValue(name, formattedDate);
    } else {
      onChange({
        target: {
          value: `${parseInt(month) + 1}-${parseInt(date) + 1}-${year}`,
        },
      });
    }

    setSelectedDate(new Date(year, month, date, 12));
  }, [month, date, year]);

  const monthOptions = useMemo(
    () =>
      map(
        (x) => ({ label: format(x, 'MMMM'), value: getMonth(x) }),
        eachMonthOfInterval({
          start: new Date(2020, 0, 1),
          end: new Date(2020, 11, 1),
        })
      ),
    [year]
  );
  const dayOptions = useMemo(
    () =>
      map(
        (x) => ({ label: x + 1, value: x }),
        range(0, getDaysInMonth(new Date(2000, month)))
      ),
    [month]
  );
  const yearOptions = map(
    (x) => ({ label: format(x, 'yyyy'), value: getYear(x) }),
    eachYearOfInterval({
      start: new Date(),
      end: addYears(new Date(), 2),
    })
  );

  return (
    <Box direction="row-responsive" gap={isMobile ? 'medium' : 'small'}>
      <Box flex={true}>
        <Select
          onChange={(e) => setMonth(e.value)}
          options={monthOptions}
          value={month}
          labelKey="label"
          valueKey={{ key: 'value', reduce: true }}
        />
      </Box>
      <Box direction="row" gap="small" flex={true}>
        <Select
          onChange={(e) => setDate(e.value)}
          options={dayOptions}
          value={date}
          labelKey="label"
          valueKey={{ key: 'value', reduce: true }}
        />
        <Select
          onChange={(e) => setYear(e.value)}
          options={yearOptions}
          value={year}
          labelKey="label"
          valueKey={{ key: 'value', reduce: true }}
        />
      </Box>
    </Box>
  );
};

InputDateTrioSelect.propTypes = {
  form: PropTypes.object.isRequired,
};

export default memo(InputDateTrioSelect);
