import React, { memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Button, Text, Paragraph, FormField } from 'grommet';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { getMonth, getDate, getYear } from 'date-fns';
import { identity, map, keys } from '../../lib/nodash';

import IconRightArrow from '../Icons/IconArrow';
import Loading from '../Loading';
import InputTextArea from '../Inputs/InputTextArea';
import InputText from '../Inputs/InputText';
import InputDateTrioSelect from '../Inputs/InputDateTrioSelect';
import useAddToCart from '../Cart/useAddToCart';

const FormDigitalGiftCard = ({ variantId, onComplete = identity, ...rest }) => {
  const today = useMemo(() => new Date(), []);
  const buttonRef = useRef();
  const { addToCart } = useAddToCart();

  return (
    <Box {...rest}>
      <Formik
        validateOnMount={false}
        initialValues={{
          'Recipient name': '',
          'Recipient email': '',
          'Send on': `${getMonth(today) + 1}-${getDate(today)}-${getYear(
            today
          )}`,
          Message: "A gift for you! Can't wait to see your new backdrop.",
          __shopify_send_gift_card_to_recipient: 'true',
        }}
        validate={(values) => {
          const errors = {};
          if (!values['Recipient name'] || values['Recipient name'] === '') {
            errors['Recipient name'] = 'Required';
          }
          if (!values['Recipient email'] || values['Recipient email'] === '') {
            errors['Recipient email'] = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
              values['Recipient email']
            )
          ) {
            errors['Recipient email'] = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await addToCart([
              {
                variantId,
                quantity: 1,
                customAttributes: map(
                  (key) => ({
                    key,
                    value: values[key],
                  }),
                  keys(values)
                ).filter(({ value }) => value !== ''),
              },
            ]);
          } catch (e) {
            setSubmitting(false);
          } finally {
            onComplete();
          }
        }}
      >
        {({ isSubmitting, handleSubmit, isValid, errors }) => {
          return (
            <Form id="digital_gift_card_form" onSubmit={handleSubmit}>
              <Box margin={{ bottom: 'medium' }}>
                <Heading
                  level={3}
                  style={{ textTransform: 'none' }}
                  align="center"
                  textAlign="center"
                  alignSelf="center"
                  margin={{ bottom: ';a' }}
                >
                  Give the gift of a new backdrop.
                </Heading>
                <Paragraph
                  align="center"
                  alignSelf="center"
                  size="18px"
                  textAlign="center"
                >
                  Let&apos;s capture some info on the gift recipient here. You
                  can check out with your information on the next screen.
                </Paragraph>
              </Box>
              <Box
                direction="row-responsive"
                gap="large"
                className="row"
                fill="horizontal"
                justify="between"
              >
                <Box
                  className="fields-container"
                  flex={true}
                  gap="small"
                  basis="40%"
                >
                  <FormField
                    htmlFor="Recipient name"
                    label="Recipient name"
                    className="line-item-property__field"
                    required={true}
                    error={errors['Recipient name']}
                  >
                    <Field
                      name="Recipient name"
                      id="Recipient name"
                      placeholder="..."
                      component={InputText}
                    />
                  </FormField>

                  <FormField
                    htmlFor="Recipient email"
                    label="Recipient's email address"
                    className="line-item-property__field"
                    required={true}
                    error={errors['Recipient email']}
                  >
                    <Field
                      id="Recipient email"
                      name="Recipient email"
                      placeholder="..."
                      component={InputText}
                    />
                  </FormField>
                </Box>
                <Box
                  className="fields-container"
                  flex={true}
                  gap="small"
                  basis="50%"
                >
                  <FormField
                    htmlFor="Send on"
                    label="When would you like us to send this?"
                    required={true}
                    error={errors['Send on']}
                  >
                    <Field
                      component={InputDateTrioSelect}
                      id="Send on"
                      name="Send on"
                    />
                    <ErrorMessage name="Send on">
                      {(msg) => <Text color="status-error">{msg}</Text>}
                    </ErrorMessage>
                  </FormField>

                  <FormField
                    htmlFor="Message"
                    label="Add a personalized note"
                    error={errors['Message']}
                  >
                    <Field
                      component={InputTextArea}
                      id="Message"
                      name="Message"
                      placeholder="A gift for you! Can't wait to see your new backdrop."
                    />
                  </FormField>

                  <Field
                    type="hidden"
                    id="__shopify_send_gift_card_to_recipient"
                    name="__shopify_send_gift_card_to_recipient"
                  />

                  <Box flex={true} justify="end">
                    <Button
                      ref={buttonRef}
                      primary
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      label="Continue to Checkout"
                      icon={
                        isSubmitting ? (
                          <Loading />
                        ) : (
                          <IconRightArrow size="small" direction="right" />
                        )
                      }
                      reverse
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormDigitalGiftCard.propTypes = {
  variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onComplete: PropTypes.func,
};

export default memo(FormDigitalGiftCard);
